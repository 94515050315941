import React from 'react'

import {PropTypes} from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import GetImage from '../Image'
import {
  AvailableColors,
  ColorBorder,
  ColorDisplay,
  ColorsContainer,
  ColorsDisplayTitle,
  ColorsSpan,
  ColorWrapper,
  Container,
  Heading,
  ItemGenre,
  ItemImage,
  ItemImageLink,
  ItemImageWrapper,
  ItemName,
  ListItem,
  OriginalPrice,
  Price,
  ProductCreditsContainer,
  RelatedProductsList,
  RelatedProductsWrapper,
  Separator,
  Title,
  TitleWrapper
} from './RelatedStyles'
import Currency from '../../../infra/utils/Currency'
import getTranslationNameFromArray from '../../utils/getTranslationNameFromArray'

const Related = ({
  user,
  relatedProducts,
  colorObject,
  sizesObject,
  activeLanguage
}) => {
  //const [selectedColor, setSelectedColor] = useState('')
  const colorList = colorObject.filter(
    (vl, ind, self) => ind === self.findIndex((x) => x.html === vl.html)
  )
  //const sizesList = Array.from(new Set(sizesObject))

  return (
    <Container>
      <Heading>
        <TitleWrapper>
          <Title
            style={{color: user.configuration?.layoutColors?.contentText}}
          >
            <Translate id='RELATED_TITLE' />
          </Title>
        </TitleWrapper>
      </Heading>
      <RelatedProductsWrapper>
        <RelatedProductsList>
          {relatedProducts?.slice(0, 3)?.map((product, index) => (
            <ListItem key={index}>
              <ItemGenre
                style={{
                  color: user.configuration?.layoutColors?.contentText
                }}
              >
                {
                  product?.product?.mainCategory?.categoryTranslation.find(
                    (x) => x.languageId === activeLanguage.code
                  )?.name
                }
              </ItemGenre>
              <ItemName>
                {getTranslationNameFromArray(
                  product.product.productTranslation,
                  activeLanguage.code
                )}
              </ItemName>
              <ItemImageWrapper>
                <ItemImageLink to={`/products/${product?.productId}`}>
                  <ItemImage
                    src={GetImage(
                      product?.product?.productImage.find((x) => x.cover)
                    )}
                    alt={
                      product?.product?.productTranslation.find(
                        (x) => x.languageId === activeLanguage.code
                      )?.name
                    }
                  />
                </ItemImageLink>
              </ItemImageWrapper>
              <ProductCreditsContainer>
                <Price>
                  {Currency.format(
                    product?.product?.totalValue,
                    user.currencyCode
                  )}
                </Price>
                {/* <OriginalPrice>
                  {Currency.format(
                    product?.product?.originalPrice,
                    user.currencyCode
                  )}
                </OriginalPrice> */}
              </ProductCreditsContainer>
              <Separator />
              <AvailableColors>
                <>
                  <ColorsDisplayTitle>
                    <Translate id='COLORS_TITLE' />
                  </ColorsDisplayTitle>
                  <ColorsSpan />
                  <ColorsContainer>
                    {colorList.map((color, ind) => (
                      <ColorWrapper key={ind}>
                        <ColorBorder>
                          <ColorDisplay
                            style={{backgroundColor: `${color.html}`}}
                          />
                        </ColorBorder>
                      </ColorWrapper>
                    ))}
                  </ColorsContainer>
                </>
              </AvailableColors>
            </ListItem>
          ))}
        </RelatedProductsList>
      </RelatedProductsWrapper>
    </Container>
  )
}

Related.propTypes = {
  relatedProducts: PropTypes.array.isRequired,
  colorObject: PropTypes.array.isRequired,
  sizesObject: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(Related))
