import React from 'react'

import {Tooltip} from 'antd'
import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import {StyledButton} from './ProductFormStyles'

const AddToBagButton = ({
  user,
  submitting,
  values,
  currentSize,
  hasReachedCreditLimit,
  fittingPending,
  noCreditsAvaliable,
  disableMoney,
  tooltipText
}) => {
  const colors = {
    contentText: user?.configuration?.layoutColors?.contentText,
    contentButtonText: user?.configuration?.layoutColors?.contentButtonText
  }

  const isButtonDisabled = () => {
    if (!user.allowOrdersWithMoney) {
      return hasReachedCreditLimit || noCreditsAvaliable
    }

    return (
      submitting ||
      (!values.size && !currentSize) ||
      fittingPending ||
      disableMoney
    )
  }

  const shouldShowTooltip = () =>
    (hasReachedCreditLimit && !user.allowOrdersWithMoney) ||
    (user.allowOrdersWithMoney && disableMoney)

  const buttonProps = {
    color: colors.contentText,
    hoverColor: colors.contentButtonText,
    border: colors.contentText,
    backgroundColor: colors.contentText,
    type: 'submit'
  }

  const button = (
    <StyledButton
      {...buttonProps}
      disabled={shouldShowTooltip() || isButtonDisabled()}
    >
      <Translate id='ADD_BAG' />
    </StyledButton>
  )

  return shouldShowTooltip() ? (
    <Tooltip title={tooltipText}>
      <div>{button}</div>
    </Tooltip>
  ) : (
    button
  )
}

AddToBagButton.propTypes = {
  user: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  currentSize: PropTypes.number,
  hasReachedCreditLimit: PropTypes.bool.isRequired,
  fittingPending: PropTypes.bool.isRequired,
  noCreditsAvaliable: PropTypes.bool.isRequired,
  disableMoney: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string.isRequired
}

AddToBagButton.defaultProps = {
  currentSize: undefined
}

export default AddToBagButton
