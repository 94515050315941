import React from 'react'

import PropTypes from 'prop-types'
import {withLocalize} from 'react-localize-redux'
import {useSelector} from 'react-redux'

import formatCurrency from '../../utils/formatCurrency'
import GetImage from '../Image'
import {
  Recommended,
  RecommendedImage,
  RecommendedLink,
  RecommendedName,
  RecommendedPrice
} from './BagStyles'

const BagRelatedItem = ({product, activeLanguage}) => {
  const {currencyCode} = useSelector((state) => state.user)

  return (
    <Recommended key={product?.productId}>
      <RecommendedLink href={`/products/${product.productId}`}>
        <RecommendedImage
          src={GetImage(
            product?.product?.productImage.find((x) => x?.cover)
          )}
          alt={
            product?.product?.productImage.find((x) => x?.cover)?.image
              ?.filename
          }
        />
        <RecommendedName>
          {
            product?.product?.productTranslation?.find(
              (x) => x?.languageId === activeLanguage?.code
            )?.name
          }
        </RecommendedName>
        <RecommendedPrice>
          {formatCurrency(product?.product?.totalValue, currencyCode)}
        </RecommendedPrice>
      </RecommendedLink>
    </Recommended>
  )
}

BagRelatedItem.propTypes = {
  product: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

export default withLocalize(BagRelatedItem)
